// import { Navigate, useRoutes } from 'react-router-dom';
import { useRoutes } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
//

import {
  ApplicationPage,
  DropPage,
  LoginPage,
  // RegisterPage,
  ResetPasswordPage,
  VerifyCodePage,
  ComingSoonPage,
  // HomePage,
  MaintenancePage,
  Page404,
  Page500,
  SupportPage,
} from "./elements";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Main layout
    {
      element: <MainLayout/>,
      children: [
        {path: 'support', element: <SupportPage/>},
        // E-commerce
        // {path: 'landing', element: <EcommerceLandingPage/>},
        // {path: 'products', element: <EcommerceProductsPage/>},
        // {path: 'product', element: <EcommerceProductPage/>},
        // {path: 'cart', element: <EcommerceCartPage/>},
        // {path: 'checkout', element: <EcommerceCheckoutPage/>},
        // {path: 'order-completed', element: <EcommerceOrderCompletedPage/>},
        // {path: 'wishlist', element: <EcommerceWishlistPage/>},
        // {path: 'compare', element: <EcommerceComparePage/>},
        // {
        //   path: 'account',
        //   children: [
        //     {path: 'personal', element: <EcommerceAccountPersonalPage/>},
        //     {path: 'wishlist', element: <EcommerceAccountWishlistPage/>},
        //     {path: 'vouchers', element: <EcommerceAccountVouchersPage/>},
        //     {path: 'orders', element: <EcommerceAccountOrdersPage/>},
        //     {path: 'payment', element: <EcommerceAccountPaymentPage/>},
        //   ],
        // },
      ],
    },
    // Simple layout
    {
      element: <SimpleLayout/>,
      children: [
        {element: <DropPage/>, index: true},
        {path: 'drop', element: <DropPage/>},
        // { path: 'payment', element: <PaymentPage /> },
        // { path: 'pricing-01', element: <Pricing01Page /> },
        // { path: 'pricing-02', element: <Pricing02Page /> },
        {
          path: 'auth',
          children: [
            {element: <LoginPage/>, index: true},
          ],
        },
      ],
    },
    // Compact layout
    {
      element: <CompactLayout/>,
      children: [
        {path: 'coming-soon', element: <ComingSoonPage/>},
        {path: 'maintenance', element: <MaintenancePage/>},
        {path: '500', element: <Page500/>},
        {path: '404', element: <Page404/>},
        {path: 'reset-code', element: <ResetPasswordPage/>},
        {path: 'verify-code', element: <VerifyCodePage/>},
        {
          path: 'auth',
          children: [
            // {path: 'auth', element: <LoginPage/>},
            {path: 'reset-code', element: <ResetPasswordPage/>},
            {path: 'verify-code', element: <VerifyCodePage/>},
          ],
        },
      ],
    },
    // {path: '*', element: <Navigate to="/404" replace/>},
    {path: '*', element: <ApplicationPage/>},
  ]);
}
