import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={ <LoadingScreen/> }>
      <Component { ...props } />
    </Suspense>
  );

// ----------------------------------------------------------------------
export const ApplicationPage = Loadable(
  lazy(() => import('../pages/application'))
);

export const InvoicePage = Loadable(
  lazy(() => import('../pages/InvoiceDetails'))
);

export const DropPage = Loadable(
  lazy(() => import('../pages/drop'))
);

// AUTH
export const LoginPage = Loadable(
  lazy(() => import('../pages/auth/Login'))
);

export const RegisterPage = Loadable(
  lazy(() => import('../pages/auth/Register'))
);

export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// COMMON
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoon')));
export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/Maintenance')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const SupportPage = Loadable(lazy(() => import('../pages/Support')));