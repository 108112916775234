// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
/* eslint-disable import/no-unresolved */
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// Firebase
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// routes
import Routes from "./routes";
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { ThemeSettings, SettingsProvider } from './components/settings';
import MotionLazyContainer from './components/animate/MotionLazyContainer';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDZaVXnznRphhtNjvfTx7KGCHZovO-ha1g",
  authDomain: "intronode-web.firebaseapp.com",
  projectId: "intronode-web",
  storageBucket: "intronode-web.appspot.com",
  messagingSenderId: "662508216207",
  appId: "1:662508216207:web:2ae600e845c8241f6bf5fa",
  measurementId: "G-4PY25BLRRF"
};

export default function App() {
  // Initialize Firebase
  const firebaseApp = initializeApp(firebaseConfig);
  const analytics = getAnalytics(firebaseApp);

  window.db = getFirestore();

  return (
    <HelmetProvider>
      <LocalizationProvider dateAdapter={ AdapterDateFns }>
        <SettingsProvider>
          <BrowserRouter>
            <ScrollToTop/>
            <ThemeProvider>
              <ThemeSettings>
                <MotionLazyContainer>
                  <Routes/>
                </MotionLazyContainer>
              </ThemeSettings>
            </ThemeProvider>
          </BrowserRouter>
        </SettingsProvider>
      </LocalizationProvider>
    </HelmetProvider>
  );
};
