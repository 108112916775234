// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

// Category
export const CATEGORIES = [
  {
    name: "의류",
    subs: [
      {code: "1", name: "상의"},
      {code: "2", name: "하의 - 바지"},
      {code: "3", name: "하의 - 치마, 원피스"},
      {code: "4", name: "수영복"},
      {code: "5", name: "유아의류 (만 3세 미만)"},
      {code: "6", name: "가죽 제품"},
      {code: "7", name: "모피 제품"},
      {code: "8", name: "장갑, 넥타이, 손수건, 머플러, 넥타이 등"}
    ]
  },
  {code: "9", name: "신발류"},
  {
    name: "가방류",
    subs: [
      {code: "10", name: "핸드백"},
      {code: "11", name: "여행용, 케이스"},
      {code: "12", name: "백팩"},
      {code: "13", name: "일반 가방"}
    ]
  },
  {code: "14", name: "지갑, 동전지갑"},
  {code: "15", name: "모자"},
  {code: "16", name: "선글라스"},
  {code: "17", name: "헤어핀, 헤어밴드"},
  {code: "18", name: "화장품 - 일반"},
  {code: "19", name: "향수 (2oz/60ml 이하)"},
  {
    name: "시계",
    subs: [
      {code: "20", name: "손목시계"},
      {code: "21", name: "손목시계 외"}
    ]
  }, {
    name: "장난감",
    subs: [
      {code: "22", name: "완구"},
      {code: "23", name: "피규어"}
    ]
  },
  {code: "24", name: "보드게임, 레고(LEGO)"},
  {
    name: "가구",
    subs: [
      {code: "25", name: "식탁, 책상, 책장, 의자 등"},
      {code: "26", name: "매트리스"}
    ]
  },
  {code: "27", name: "게임기기"},
  {code: "28", name: "고무제품 (타이어 등)"},
  {code: "29", name: "골동품, 예술품"},
  {code: "30", name: "귀금속"},
  {
    name: "기계, 공구, 모터 등",
    subs: [
      {code: "31", name: "수공구 (공구류)"},
      {code: "32", name: "기계부품"},
      {code: "33", name: "측정, 분석기기"},
      {code: "34", name: "전동공구 및 기타 (인쇄, 운반기기 등)"},
      {code: "35", name: "미싱-재봉틀"},
      {code: "36", name: "모터"}
    ]
  },
  {code: "37", name: "난방용품/전열기"},
  {code: "38", name: "도자기(그릇, 컵 등)"},
  {
    name: "목욕용품",
    subs: [
      {code: "39", name: "샴푸, 린스, 면도용품"},
      {code: "40", name: "바디클렌져, 바디워시"},
      {code: "41", name: "비누, 폼클렌져"}
    ]
  }, {
    name: "문구",
    subs: [
      {code: "42", name: "노트, 편지, 엽서, 종이제품"},
      {code: "43", name: "접착제"},
      {code: "44", name: "필기용품(연필, 크레용)"},
      {code: "45", name: "물감, 페인트"}
    ]
  },
  {code: "46", name: "방향제"},
  {
    name: "사진관련용품",
    subs: [
      {code: "47", name: "디지털카메라"},
      {code: "48", name: "카메라, 렌즈"},
      {code: "49", name: "필름, 인화지 등"}
    ]
  }, {
    name: "생활잡화",
    subs: [
      {code: "50", name: "라이터, 담배파이프"},
      {code: "51", name: "브러쉬"},
      {code: "52", name: "우산, 지팡이"},
      {code: "53", name: "기저귀, 위생용품(펄프제)"}
    ]
  },
  {code: "54", name: "선박, 항공기 부품"},
  {code: "55", name: "스포츠용품, 운동기구"},
  {code: "56", name: "악기"},
  {code: "57", name: "안경"},
  {code: "58", name: "양초"},
  {code: "59", name: "오토바이, 자전거용품류"},
  {code: "60", name: "왁스, 광택제"},
  {code: "61", name: "유리제품 (주방용기,거울, 컵, 장식품 등)"},
  {code: "62", name: "음악, 영상 (CD, DVD, LP, BLUERAY)"},
  {code: "63", name: "자동차용품류, 부품"},
  {code: "64", name: "자전거"},
  {code: "65", name: "유모차"},
  {code: "66", name: "카시트"},
  {
    name: "전자제품",
    subs: [
      {code: "67", name: "전자제품일반"},
      {code: "68", name: "통신기기"},
      {code: "69", name: "노트북, 컴퓨터"},
      {code: "70", name: "모니터 (컴퓨터용)"},
      {code: "71", name: "태블릿PC"},
      {code: "72", name: "사운드바 (스피커)"},
    ]
  },
  {code: "73", name: "조명기구, 램프, 전구"},
  {code: "74", name: "종이,서적,인쇄물"},
  {
    name: "주방용품, 위생용품",
    subs: [
      {code: "75", name: "일반화장지, 냅킨, 키친타올"},
      {code: "76", name: "기저귀 위생용품(펄프제)"},
      {code: "77", name: "식기세제"},
      {code: "78", name: "주방기기(주방가전포함)"},
      {code: "79", name: "주방용기-나무"},
      {code: "80", name: "주방용기-종이"},
      {code: "81", name: "주방용기-철제"},
      {code: "82", name: "주방용기-플라스틱"},
    ]
  }, {
    name: "침구, 커튼",
    subs: [
      {code: "83", name: "이불,담요,침낭,침대커버,베개커버"},
      {code: "84", name: "베개, 방석, 쿠션"},
      {code: "85", name: "벽지"},
      {code: "86", name: "양탄자"},
      {code: "87", name: "커튼"},
    ]
  }, {
    name: "컴퓨터 저장매체",
    subs: [
      {code: "88", name: "HDD"},
      {code: "89", name: "SSD"},
      {code: "90", name: "메모리 카드"},
      {code: "91", name: "USB 메모리"},
    ]
  }, {
    name: "TV (50인치 미만)",
    subs: [
      {code: "92", name: "LCD TV"},
      {code: "93", name: "LED TV"},
      {code: "94", name: "PDP TV"},
    ]
  }, {
    name: "TV (50인치 이상)",
    subs: [
      {code: "95", name: "LCD"},
      {code: "96", name: "LED"},
      {code: "97", name: "PDP"},
    ]
  },
  {code: "98", name: "텐트"},
  {code: "118", name: "*알코올 손소독제 (세정제)"},
  {code: "119", name: "*마스크 (방역용 황사 일회용 마스크-면 부직포 등)"},
  {code: "120", name: "*가정용 체온계 (인당 1개만 가능)"},
  {code: "100", name: "식품류"},
  {code: "101", name: "영양제, 건강보조제"},
  {code: "102", name: "의약품"},
  {code: "103", name: "의약외품 (치약 치실 붕대 거즈 밴드 등)"},
  {code: "104", name: "의약외품 (손세정제)"},
  {code: "105", name: "의료기기 (체온계, 콧물흡입기 등)"},
  {code: "106", name: "진단용시약 (테스트기)"},
  {code: "107", name: "기능성화장품 (미백,주름개선 등)"},
  {code: "108", name: "향수 2oz(60ML) 초과"},
  {code: "109", name: "애완동물용 사료, 식품, 영양제"},
  {code: "110", name: "콘택트렌즈"},
  {code: "111", name: "농림축산물(검역필요)"},
  {code: "112", name: "주류"},
  {code: "113", name: "담배"},
  {code: "114", name: "전자담배 담배대용물"},
  {code: "115", name: "무기류, 등산용칼, 조준경"},
  {code: "116", name: "소형감청장비"}
];

export const CATEGORY = {
  "1": "의류 - 상의",
  "2": "의류 - 바지",
  "3": "의류 - 치마, 원피스",
  "4": "의류 - 수영복",
  "5": "의류 - 유아 (만 3세 미만)",
  "6": "의류 - 가죽 제품",
  "7": "의류 - 모피 제품",
  "8": "의류 - 장갑, 넥타이, 손수건, 머플러, 넥타이 등",
  "9": "신발류",
  "10": "가방 - 핸드백",
  "11": "가방 - 여행용, 케이스",
  "12": "가방 - 백팩",
  "13": "가방 - 일반",
  "14": "지갑, 동전지갑",
  "15": "모자",
  "16": "선글라스",
  "17": "헤어핀, 헤어밴드",
  "18": "화장품 - 일반",
  "19": "향수 (2oz/60ml 이하)",
  "20": "시계 - 손목시계",
  "21": "시계 - 손목시계 외",
  "22": "장난감 - 완구",
  "23": "장난감 - 피규어",
  "24": "보드게임, 레고(LEGO)",
  "25": "가구 - 식탁, 책상, 책장, 의자 등",
  "26": "가구 - 매트리스",
  "27": "게임기기",
  "28": "고무제품 (타이어 등)",
  "29": "골동품, 예술품",
  "30": "귀금속",
  "31": "기계, 공구 - 수공구 (공구류)",
  "32": "기계, 공구 - 기계부품",
  "33": "기계, 공구 - 측정, 분석기기",
  "34": "기계, 공구 - 전동공구 및 기타 (인쇄, 운반기기 등)",
  "35": "기계, 공구 - 미싱, 재봉틀",
  "36": "기계, 공구 - 모터",
  "37": "난방용품/전열기",
  "38": "도자기(그릇, 컵 등)",
  "39": "목욕용품 - 샴푸, 린스, 면도용품",
  "40": "목욕용품 - 바디클렌져, 바디워시",
  "41": "목욕용품 - 비누, 폼클렌져",
  "42": "문구 - 노트, 편지, 엽서, 종이제품",
  "43": "문구 - 접착제",
  "44": "문구 - 필기용품 (연필, 크레용)",
  "45": "문구 - 물감, 페인트",
  "46": "방향제",
  "47": "사진관련용품 - 디지털카메라",
  "48": "사진관련용품 - 카메라, 렌즈",
  "49": "사진관련용품 - 필름, 인화지 등",
  "50": "생활잡화 - 라이터, 담배파이프",
  "51": "생활잡화 - 브러쉬",
  "52": "생활잡화 - 우산, 지팡이",
  "53": "생활잡화 - 기저귀, 위생용품(펄프제)",
  "54": "선박, 항공기 부품",
  "55": "스포츠용품, 운동기구",
  "56": "악기",
  "57": "안경",
  "58": "양초",
  "59": "오토바이, 자전거용품류",
  "60": "왁스, 광택제",
  "61": "유리제품 (주방용기,거울, 컵, 장식품 등)",
  "62": "음악, 영상 (CD, DVD, LP, BLUERAY)",
  "63": "자동차용품류, 부품",
  "64": "자전거",
  "65": "유모차",
  "66": "카시트",
  "67": "전자제품 - 일반",
  "68": "전자제품 - 통신기기",
  "69": "전자제품 - 노트북, 컴퓨터",
  "70": "전자제품 - 모니터 (컴퓨터용)",
  "71": "전자제품 - 태블릿PC",
  "72": "전자제품 - 사운드바 (스피커)",
  "73": "조명기구, 램프, 전구",
  "74": "종이,서적,인쇄물",
  "75": "주방, 위생용품 - 일반화장지, 냅킨, 키친타올",
  "76": "주방, 위생용품 - 기저귀 위생용품(펄프제)",
  "77": "주방, 위생용품 - 식기세제",
  "78": "주방, 위생용품 - 주방기기 (주방가전포함)",
  "79": "주방, 위생용품 - 나무 주방용기",
  "80": "주방, 위생용품 - 종이 주방용기",
  "81": "주방, 위생용품 - 철제 주방용기",
  "82": "주방, 위생용품 - 플라스틱 주방용기",
  "83": "침구 - 이불, 담요, 침낭, 침대커버, 베개커버",
  "84": "침구 - 베개, 방석, 쿠션",
  "85": "침구 - 벽지",
  "86": "침구 - 양탄자",
  "87": "커튼",
  "88": "컴퓨터 저장매체 - HDD",
  "89": "컴퓨터 저장매체 - SSD",
  "90": "컴퓨터 저장매체 - 메모리 카드",
  "91": "컴퓨터 저장매체 - USB 메모리",
  "92": "TV (50인치 미만) - LCD",
  "93": "TV (50인치 미만) - LED TV",
  "94": "TV (50인치 미만) - PDP TV",
  "95": "TV (50인치 이상) - LCD",
  "96": "TV (50인치 이상) - LED",
  "97": "TV (50인치 이상) - PDP",
  "98": "텐트",
  "118": "*알코올 손소독제 (세정제)",
  "119": "*마스크 (방역용 황사 일회용 마스크-면 부직포 등)",
  "120": "*가정용 체온계 (인당 1개만 가능)",
  "100": "식품류",
  "101": "영양제, 건강보조제",
  "102": "의약품",
  "103": "의약외품 (치약 치실 붕대 거즈 밴드 등)",
  "104": "의약외품 (손세정제)",
  "105": "의료기기 (체온계, 콧물흡입기 등)",
  "106": "진단용시약 (테스트기)",
  "107": "기능성화장품 (미백,주름개선 등)",
  "108": "향수 2oz(60ML) 초과",
  "109": "애완동물용 사료, 식품, 영양제",
  "110": "콘택트렌즈",
  "111": "농림축산물(검역필요)",
  "112": "주류",
  "113": "담배",
  "114": "전자담배 담배대용물",
  "115": "무기류, 등산용칼, 조준경",
  "116": "소형감청장비",
};

export const TAC = `※ 구매/결제 대행이란?
언어나 결제 카드 등의 문제로 직접 구매가 어려운 경우, 해외 쇼핑몰에서의 구매부터 배송까지 대행해 드리는 서비스를 말합니다.

※ 인트로노드 구매대행 신청서 작성방법
- 구매대행은 미국, 유럽, 일본만 현재 가능합니다.
- 구매대행 신청서 작성 전에 꼭 수입금지 품목을 확인하세요.
- 구매대행 신청 후 서비스비용이 책정되면 상품가, 배송비, 통관시 관부가세(관부가세 발생시) 결제가 필요하며 해당 내용은 제출하신 이메일을 통해 알려드리고 있습니다.
- 구매대행 신청서 작성시 구매요청 사이트가 정식 사이트인지 꼭 확인부탁드립니다
  정식 사이트나 공식 홈페이지가 아닌경우 물건 미발송, 가짜제품 등이 배송되는 등 피해가 발생하고 있습니다.
  인트로노드는 고객님의 신청서 작성대로 결제 맟 구매를 대행해 드리기 때문에 사이트에 대한 확인까지 불가하며 이해 대한 피해는 일체 책임이 없음을 다시한번 말씀드립니다. 
- 결제 및 구매대행 신청 후 상품가가 책정되면 상품가, 수수료, 배송비의 선결제가 필요합니다.
- 적용할 수 있는 할인코드는 반드시 명시해 주시고 미기제로 인한 손해는 인트로노드에서 책임지지 않습니다.
- 구매가 진행된 이후 신청서 취소 및 상품 정보 수정은 불가합니다. 단, 출고 전까지 배송정보 수정은 가능합니다.
- 동일한 쇼핑몰의 상품만 한번에 신청 가능합니다.
- 신청서 등록 시점과 구매 시점의 재고는 달라질 수 있습니다. 경우에 따라 재고가 없어 구매가 안되는 경우도 있으며, 이 경우 인트로노드는 그에 대한 책임이 없음을 알려드립니다.

※ 유의사항
- 구매대행 신청서 작성시 정확한 상품주소(URL)을 입력해주시기 바랍니다.
  온라인 구매대행 특성상 고객님이 입력해주신 상품주소로 제품을 구매하며 제품검수는 인보이스상 색상, 사이즈 등 기본검수만 진행해드립니다.
  정밀 검수 신청시 일반검수외에 고객님이 요청사항 등을 반드시 메모에 기재해 주시기 바랍니다.
  메모에 남겨져 있지 않은 특이 사항에 대해선 책임지지 않으니 유의 부탁드립니다.

(상품별검수규칙)
* 의류, 신발, 가방 등의 재봉불량, 구멍, 부속재 누락, 오염유무는 정밀검수 선택시에만 확인가능합니다.
* 이불, 침낭, 텐트, 커튼 등 부피가 큰 제품은 정밀검수 진행이 불가능합니다.
* 전자제품은 내부 구성품 확인 및 작동유무에 대한 확인이 불가능합니다.
* 제품을 착용해보거나 작동해보는 검수요청 서비스는 지원되지 않습니다.
* 완포장 박스로 도착하는 유모차, 카시트, 작동완구 등은 오픈하지 않으므로 내부구성품 확인 및 제품하자나 파손여부에 대한 검수가 불가능합니다.
* 가구, 조명류 등 일부 생활용품의 스크래치, 작동여부, 내부 구성품은 확인이 불가능합니다. 

※ 배송중 사고에 대한 처리규정
- 배송 중에 파손 사고가 발생할 경우 배송대행서비스 이용약관이 적용 됩니다.
  구매하실 상품이 파손시 보상에서 제외되는 취급제한품목에 해당하는지 확인하신 후 이용바랍니다.
- 배송신청서 작성시 운송보험가입 및 특수포장요청 등 사고예방과 사고보상에 대한 옵션들은 회원이 판단하여 직접 선택해야합니다.

※ 구매대행 환불/취소/반품
- 구매대행 신청건은 구매 시점에 해당 쇼핑몰의 재고가 소진되었거나, 쇼핑몰에서 주문이 취소처리가 된 경우 결제하신 금액 전체를 환불해 드립니다.
- 현재 쇼핑몰에서 주문완료가 된 이후에는 취소처리가 불가능 합니다.
  부득이한 사정으로 취소처리를 원하시는 경우 판매처에 반품/환불 가능 여부 확인 후 연락 드리도록 하겠습니다.
  배송이 시작된 이후 반품을 해야 하는 경우 제반 수수료와 반품 택배비가 청구됩니다.
  판매처에서 반품/환불이 불가한 경우 주문취소는 불가합니다.
  이점 유의해 주시기 바랍니다.

※ 필수사항
- 물품의 통관 가능 여부를 관세청(125) 및 세관에 확인하신 후 구매대행 요청을 해 주시길 부탁드립니다.
- 통관이 안되는 이유로 반송 및 폐기처분 시 인트로노드는 어떠한 책임도 지지 않습니다.
- 수입통관 반입 금지 성분 관련 반입 여부 관련 상담은 농림축산검역본부 인천공항지역본부 화물검역과(032-740-2625)로 문의해 주시기 바랍니다.`;
