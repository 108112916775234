// API
// ----------------------------------------------------------------------

export const GOOGLE_MAP_API = process.env.REACT_APP_MAP_API;
export const EXCHANGE_RATE_API_KEY = 'TvkuPIcRMdVcsYaqCQYMwOQArdDRZcDL';

// LAYOUT
// ----------------------------------------------------------------------

export const NAV = {
  W_BASE: 260,
  W_DRAWER: 280,
  //
  H_ITEM: 48,
  H_ITEM_SUB: 36,
};

export const ICON = {
  NAV_ITEM: 24,
};

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
};
