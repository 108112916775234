import PropTypes from 'prop-types';
import { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

function Logo({single = false, sx}) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const singleLogo = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 640 640"
    >
      <path
        fill="#38D033"
        d="M 109.35,597.04
           C 107.54,598.61 106.11,600.15 104.70,601.71
             103.01,603.58 101.35,605.48 99.04,608.08
             98.08,605.78 97.13,604.53 97.09,603.25
             96.32,583.04 94.27,562.74 95.56,542.66
             96.28,531.54 94.81,520.79 95.06,509.88
             95.51,490.84 96.35,471.80 97.00,452.76
             97.36,441.99 97.52,431.21 98.03,420.44
             98.92,401.95 99.30,383.40 101.25,365.01
             102.78,350.53 103.71,336.11 104.24,321.56
             104.67,309.49 107.17,297.49 108.76,285.46
             110.17,274.89 111.62,264.33 113.00,253.76
             114.99,238.47 117.08,223.20 118.88,207.89
             120.56,193.69 123.38,179.82 128.67,166.47
             129.47,164.43 129.02,161.86 128.98,159.54
             128.81,146.53 130.88,134.03 136.90,122.34
             139.85,116.62 142.73,116.28 146.81,121.24
             147.64,122.25 148.70,123.07 149.74,124.06
             155.97,121.42 158.76,116.20 161.05,110.39
             163.86,103.28 166.92,96.26 169.99,89.25
             170.49,88.09 171.36,86.78 172.42,86.22
             174.81,84.96 177.59,83.11 179.89,83.49
             181.26,83.71 182.69,87.67 182.87,90.04
             183.30,95.71 183.00,101.44 183.00,107.14
             183.00,109.14 182.61,111.24 183.10,113.11
             183.80,115.81 185.16,118.33 186.24,120.92
             188.49,118.73 191.48,116.90 192.85,114.25
             195.06,109.94 196.29,105.11 197.81,100.47
             199.40,95.62 202.62,96.48 206.02,98.02
             207.69,98.78 209.81,100.81 210.80,100.33
             213.48,99.04 216.12,97.07 217.95,94.72
             220.95,90.86 223.18,86.41 225.97,82.37
             227.14,80.68 228.67,79.02 230.42,78.02
             232.04,77.09 235.45,76.19 235.84,76.77
             237.23,78.85 238.80,81.84 238.28,83.98
             236.14,92.68 233.37,101.24 230.59,109.77
             225.98,123.90 221.18,137.97 216.48,152.07
             216.22,152.83 215.96,153.69 216.04,154.47
             217.29,167.60 212.19,179.69 209.93,192.24
             208.74,198.84 207.94,205.51 207.01,212.16
             205.59,222.23 204.42,232.33 202.77,242.36
             201.09,252.51 198.74,262.56 197.18,272.73
             196.80,275.16 197.60,278.98 199.26,280.14
             200.70,281.14 204.50,279.88 206.63,278.59
             221.15,269.73 235.52,260.61 249.92,251.54
             255.66,247.93 256.87,241.64 257.81,235.88
             259.47,225.67 259.58,215.22 261.12,205.00
             263.20,191.26 265.87,177.60 268.56,163.96
             272.58,143.60 276.82,123.28 281.03,102.95
             282.52,95.76 284.49,88.66 285.70,81.42
             286.28,77.99 287.90,77.21 290.90,76.95
             297.12,76.42 302.68,74.70 306.19,68.70
             308.10,65.43 310.92,66.30 314.74,71.03
             320.49,67.85 322.56,62.08 325.00,56.55
             327.44,51.04 329.65,45.39 332.51,40.10
             334.86,35.75 337.95,36.01 340.16,40.65
             342.01,44.57 343.21,48.80 344.94,53.52
             348.18,51.58 351.02,49.19 354.29,48.12
             357.25,47.15 360.69,47.60 363.92,47.43
             364.10,48.03 364.28,48.64 364.46,49.24
             366.35,47.02 368.22,44.77 370.15,42.57
             371.24,41.32 372.22,39.74 373.61,39.03
             376.32,37.65 379.29,36.77 382.15,35.69
             382.98,39.03 385.22,42.78 384.41,45.66
             380.31,60.10 375.10,74.22 370.87,88.63
             366.55,103.34 362.33,118.14 359.13,133.13
             353.63,158.86 348.77,184.74 344.01,210.62
             339.45,235.40 335.18,260.23 331.15,285.10
             328.73,299.99 327.02,314.99 324.98,329.94
             322.68,346.83 320.14,363.70 318.11,380.62
             316.71,392.23 315.80,403.90 315.05,415.56
             313.86,433.86 313.02,452.19 311.98,470.50
             311.28,482.84 310.50,495.17 309.79,507.05
             304.14,508.00 298.89,509.06 293.58,509.66
             292.53,509.78 291.06,508.39 290.19,507.35
             289.04,506.00 288.21,504.35 287.38,502.76
             285.88,499.87 284.45,500.73 283.08,502.79
             282.55,503.61 282.24,504.57 281.85,505.48
             280.44,508.74 278.75,510.88 274.53,511.52
             270.76,512.10 267.34,515.04 263.13,517.28
             260.90,512.41 258.72,507.64 256.55,502.88
             255.95,502.81 255.35,502.74 254.74,502.67
             253.16,507.30 251.59,511.93 249.99,516.56
             248.99,519.48 248.33,522.60 246.82,525.25
             244.65,529.08 241.06,529.43 236.88,528.56
             232.70,527.68 232.00,525.02 232.00,521.56
             232.00,510.85 231.63,500.13 232.09,489.44
             232.75,473.94 233.84,458.45 235.07,442.98
             236.16,429.21 237.38,415.44 239.08,401.73
             241.00,386.29 243.44,370.91 245.81,355.53
             246.47,351.25 244.79,348.55 240.23,349.17
             236.29,349.71 232.09,351.01 228.81,353.19
             215.19,362.27 201.81,371.71 188.55,381.31
             186.80,382.58 185.71,385.41 185.28,387.69
             184.22,393.32 183.43,399.04 183.06,404.75
             181.90,422.56 181.02,440.38 179.96,458.20
             179.39,467.91 178.60,477.62 178.01,487.33
             177.29,499.37 176.41,511.41 176.11,523.47
             175.92,531.06 177.24,538.70 176.83,546.27
             176.45,553.24 174.81,560.16 173.35,567.02
             173.04,568.52 170.86,570.91 170.00,570.73
             168.09,570.34 166.16,568.82 164.84,567.26
             163.76,565.97 163.64,563.92 162.94,562.27
             162.43,561.04 161.68,559.90 161.03,558.72
             160.54,558.71 160.04,558.69 159.54,558.68
             158.00,562.50 156.49,566.33 154.92,570.13
             153.64,573.22 152.31,575.70 148.55,572.31
             146.01,570.02 143.64,571.67 141.79,573.75
             138.78,577.12 135.90,580.59 132.90,584.09
             130.53,578.86 128.44,574.24 126.08,569.05
             120.92,573.91 122.24,579.57 121.04,584.44
             120.77,585.54 121.00,586.77 121.00,587.93
             121.00,596.08 117.53,598.88 109.35,597.04M 294.89,174.53
           C 296.29,175.32 298.12,177.07 299.00,176.68
             300.33,176.08 301.93,173.91 301.79,172.57
             301.33,167.99 300.16,163.48 298.97,157.43
             296.11,159.97 293.37,161.26 293.26,162.74
             293.00,166.41 293.94,170.16 294.89,174.53M 259.58,303.06
           C 261.80,305.03 264.09,307.41 267.22,305.10
             269.41,303.48 269.50,296.33 266.92,294.30
             261.88,290.31 260.20,297.30 255.72,297.93
             257.08,299.82 258.07,301.19 259.58,303.06M 148.90,325.67
           C 149.45,328.96 150.95,332.41 154.53,331.48
             157.90,330.59 157.46,326.88 156.43,323.88
             156.38,323.73 156.26,323.60 156.16,323.47
             154.91,321.74 153.32,318.39 152.47,318.57
             149.53,319.19 147.35,321.33 148.90,325.67M 210.10,308.24
           C 210.67,309.58 210.88,311.39 211.89,312.13
             213.01,312.96 215.26,313.52 216.26,312.93
             217.53,312.17 218.86,309.99 218.64,308.67
             218.42,307.32 216.44,305.43 215.10,305.31
             213.49,305.17 211.74,306.66 210.10,308.24M 279.83,281.30
           C 279.83,277.92 279.83,274.53 279.83,270.68
             277.43,271.44 275.78,271.97 273.59,272.67
             275.27,276.12 272.99,281.33 279.83,281.30M 284.02,434.79
           C 284.41,436.85 284.80,438.90 285.47,442.42
             287.06,439.60 288.11,438.32 288.49,436.86
             288.82,435.62 288.37,434.17 288.26,432.82
             286.86,433.21 285.47,433.61 284.02,434.79M 167.42,283.97
           C 169.97,280.72 169.79,279.96 165.80,277.16
             161.82,281.57 161.95,282.70 167.42,283.97M 231.83,309.47
           C 234.19,306.21 233.83,303.98 229.46,303.08
             228.99,305.73 225.78,308.91 231.83,309.47 Z"
      />
      <path
        fill="#91F861"
        d="M 537.75,268.43
           C 543.53,286.41 536.33,301.83 530.32,317.75
             527.21,325.96 524.02,333.37 516.37,338.15
             515.41,338.74 514.38,339.32 513.61,340.12
             511.51,342.30 509.52,344.59 507.91,346.38
             513.93,353.47 520.07,359.40 524.62,366.38
             532.80,378.92 534.88,392.99 531.66,407.66
             529.25,418.65 526.23,429.48 518.40,438.07
             508.28,449.19 497.49,459.52 484.40,467.27
             464.54,479.03 443.07,485.75 420.35,489.11
             412.99,490.20 405.82,492.52 398.46,493.62
             395.89,494.00 393.03,492.39 390.31,491.69
             390.99,489.18 391.78,486.68 392.31,484.14
             392.65,482.54 393.27,480.45 392.58,479.28
             390.78,476.21 390.34,474.07 393.85,471.90
             395.01,471.18 396.27,468.26 395.82,467.71
             394.07,465.57 391.79,463.74 389.40,462.32
             388.70,461.91 386.36,462.92 385.80,463.88
             383.27,468.17 381.17,468.46 378.27,464.14
             377.83,463.48 373.90,463.90 373.28,464.89
             372.17,466.66 372.54,469.28 371.95,471.45
             371.28,473.96 370.46,478.33 369.32,478.46
             365.82,478.85 362.04,477.89 358.56,476.83
             357.42,476.48 356.15,474.22 356.15,472.83
             356.14,465.06 356.50,457.28 356.76,449.51
             356.80,448.19 356.90,446.87 357.00,445.56
             358.32,429.19 359.53,412.80 361.02,396.45
             362.15,384.06 363.25,371.65 365.10,359.36
             367.72,342.00 370.70,324.68 374.23,307.49
             375.13,303.14 378.68,299.33 381.33,294.72
             379.67,293.81 377.38,292.57 374.89,291.22
             375.89,285.90 381.75,281.43 377.35,274.66
             380.92,273.48 384.14,272.69 387.11,271.35
             390.19,269.96 393.03,268.03 395.97,266.34
             393.38,264.90 390.78,263.47 388.19,262.02
             387.79,261.80 387.41,261.55 386.66,261.10
             390.23,255.92 388.58,251.31 384.32,246.46
             386.98,245.18 389.16,243.87 391.50,243.04
             409.62,236.66 428.18,232.04 447.31,230.04
             459.55,228.77 471.85,227.30 484.13,227.19
             496.78,227.09 509.71,229.31 519.14,238.30
             527.23,246.02 535.27,254.62 537.03,266.72
             537.10,267.18 537.38,267.61 537.75,268.43M 454.98,379.03
           C 452.62,379.54 450.13,379.73 447.93,380.62
             440.45,383.66 433.01,386.85 425.68,390.25
             424.45,390.82 423.37,392.63 423.04,394.05
             421.22,401.94 419.36,409.84 418.10,417.83
             417.17,423.75 417.13,429.81 416.61,436.94
             424.79,435.14 431.77,433.73 438.68,432.04
             449.03,429.52 457.05,422.89 464.33,415.77
             467.87,412.31 472.36,407.85 470.92,401.72
             468.72,392.40 463.97,384.60 454.98,379.03M 479.99,289.79
           C 477.40,282.73 470.73,278.25 463.06,277.94
             458.43,277.75 454.27,278.47 452.11,282.88
             449.76,287.66 447.85,292.67 446.05,297.68
             443.81,303.92 442.03,310.31 439.79,316.54
             437.57,322.74 434.23,328.64 435.26,336.34
             437.88,335.89 440.23,335.53 442.57,335.09
             452.63,333.16 459.13,326.32 464.88,318.59
             471.27,310.00 477.94,301.54 479.99,289.79M 392.43,423.00
           C 393.94,422.00 395.51,421.07 396.85,419.87
             396.98,419.75 395.77,417.28 394.97,417.14
             393.09,416.83 391.08,417.26 387.71,417.49
             389.64,420.20 390.63,421.60 392.43,423.00M 389.00,356.59
           C 389.71,358.04 390.42,359.50 391.13,360.95
             392.08,360.08 393.83,359.20 393.82,358.33
             393.81,356.58 392.85,354.83 392.29,353.08
             391.19,353.97 390.10,354.86 389.00,356.59M 518.00,398.41
           C 517.30,396.96 516.61,395.50 515.92,394.05
             514.95,395.12 513.30,396.13 513.20,397.28
             513.08,398.77 514.18,400.37 514.76,401.93
             515.84,401.03 516.92,400.14 518.00,398.41M 508.21,384.60
           C 509.36,385.30 510.51,386.01 511.66,386.71
             512.49,384.82 513.32,382.93 514.15,381.04
             513.54,380.66 512.94,380.29 512.33,379.91
             511.08,381.26 509.83,382.61 508.21,384.60 Z"
      />
    </svg>
  );

  const fullLogo = (
    <svg
      xmlns="http://www.w3.org/2000/svg "
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 1080 288"
    >
      <path
        fill="#38D033"
        d="M 109.35,597.04
           C 107.54,598.61 106.11,600.15 104.70,601.71
             103.01,603.58 101.35,605.48 99.04,608.08
             98.08,605.78 97.13,604.53 97.09,603.25
             96.32,583.04 94.27,562.74 95.56,542.66
             96.28,531.54 94.81,520.79 95.06,509.88
             95.51,490.84 96.35,471.80 97.00,452.76
             97.36,441.99 97.52,431.21 98.03,420.44
             98.92,401.95 99.30,383.40 101.25,365.01
             102.78,350.53 103.71,336.11 104.24,321.56
             104.67,309.49 107.17,297.49 108.76,285.46
             110.17,274.89 111.62,264.33 113.00,253.76
             114.99,238.47 117.08,223.20 118.88,207.89
             120.56,193.69 123.38,179.82 128.67,166.47
             129.47,164.43 129.02,161.86 128.98,159.54
             128.81,146.53 130.88,134.03 136.90,122.34
             139.85,116.62 142.73,116.28 146.81,121.24
             147.64,122.25 148.70,123.07 149.74,124.06
             155.97,121.42 158.76,116.20 161.05,110.39
             163.86,103.28 166.92,96.26 169.99,89.25
             170.49,88.09 171.36,86.78 172.42,86.22
             174.81,84.96 177.59,83.11 179.89,83.49
             181.26,83.71 182.69,87.67 182.87,90.04
             183.30,95.71 183.00,101.44 183.00,107.14
             183.00,109.14 182.61,111.24 183.10,113.11
             183.80,115.81 185.16,118.33 186.24,120.92
             188.49,118.73 191.48,116.90 192.85,114.25
             195.06,109.94 196.29,105.11 197.81,100.47
             199.40,95.62 202.62,96.48 206.02,98.02
             207.69,98.78 209.81,100.81 210.80,100.33
             213.48,99.04 216.12,97.07 217.95,94.72
             220.95,90.86 223.18,86.41 225.97,82.37
             227.14,80.68 228.67,79.02 230.42,78.02
             232.04,77.09 235.45,76.19 235.84,76.77
             237.23,78.85 238.80,81.84 238.28,83.98
             236.14,92.68 233.37,101.24 230.59,109.77
             225.98,123.90 221.18,137.97 216.48,152.07
             216.22,152.83 215.96,153.69 216.04,154.47
             217.29,167.60 212.19,179.69 209.93,192.24
             208.74,198.84 207.94,205.51 207.01,212.16
             205.59,222.23 204.42,232.33 202.77,242.36
             201.09,252.51 198.74,262.56 197.18,272.73
             196.80,275.16 197.60,278.98 199.26,280.14
             200.70,281.14 204.50,279.88 206.63,278.59
             221.15,269.73 235.52,260.61 249.92,251.54
             255.66,247.93 256.87,241.64 257.81,235.88
             259.47,225.67 259.58,215.22 261.12,205.00
             263.20,191.26 265.87,177.60 268.56,163.96
             272.58,143.60 276.82,123.28 281.03,102.95
             282.52,95.76 284.49,88.66 285.70,81.42
             286.28,77.99 287.90,77.21 290.90,76.95
             297.12,76.42 302.68,74.70 306.19,68.70
             308.10,65.43 310.92,66.30 314.74,71.03
             320.49,67.85 322.56,62.08 325.00,56.55
             327.44,51.04 329.65,45.39 332.51,40.10
             334.86,35.75 337.95,36.01 340.16,40.65
             342.01,44.57 343.21,48.80 344.94,53.52
             348.18,51.58 351.02,49.19 354.29,48.12
             357.25,47.15 360.69,47.60 363.92,47.43
             364.10,48.03 364.28,48.64 364.46,49.24
             366.35,47.02 368.22,44.77 370.15,42.57
             371.24,41.32 372.22,39.74 373.61,39.03
             376.32,37.65 379.29,36.77 382.15,35.69
             382.98,39.03 385.22,42.78 384.41,45.66
             380.31,60.10 375.10,74.22 370.87,88.63
             366.55,103.34 362.33,118.14 359.13,133.13
             353.63,158.86 348.77,184.74 344.01,210.62
             339.45,235.40 335.18,260.23 331.15,285.10
             328.73,299.99 327.02,314.99 324.98,329.94
             322.68,346.83 320.14,363.70 318.11,380.62
             316.71,392.23 315.80,403.90 315.05,415.56
             313.86,433.86 313.02,452.19 311.98,470.50
             311.28,482.84 310.50,495.17 309.79,507.05
             304.14,508.00 298.89,509.06 293.58,509.66
             292.53,509.78 291.06,508.39 290.19,507.35
             289.04,506.00 288.21,504.35 287.38,502.76
             285.88,499.87 284.45,500.73 283.08,502.79
             282.55,503.61 282.24,504.57 281.85,505.48
             280.44,508.74 278.75,510.88 274.53,511.52
             270.76,512.10 267.34,515.04 263.13,517.28
             260.90,512.41 258.72,507.64 256.55,502.88
             255.95,502.81 255.35,502.74 254.74,502.67
             253.16,507.30 251.59,511.93 249.99,516.56
             248.99,519.48 248.33,522.60 246.82,525.25
             244.65,529.08 241.06,529.43 236.88,528.56
             232.70,527.68 232.00,525.02 232.00,521.56
             232.00,510.85 231.63,500.13 232.09,489.44
             232.75,473.94 233.84,458.45 235.07,442.98
             236.16,429.21 237.38,415.44 239.08,401.73
             241.00,386.29 243.44,370.91 245.81,355.53
             246.47,351.25 244.79,348.55 240.23,349.17
             236.29,349.71 232.09,351.01 228.81,353.19
             215.19,362.27 201.81,371.71 188.55,381.31
             186.80,382.58 185.71,385.41 185.28,387.69
             184.22,393.32 183.43,399.04 183.06,404.75
             181.90,422.56 181.02,440.38 179.96,458.20
             179.39,467.91 178.60,477.62 178.01,487.33
             177.29,499.37 176.41,511.41 176.11,523.47
             175.92,531.06 177.24,538.70 176.83,546.27
             176.45,553.24 174.81,560.16 173.35,567.02
             173.04,568.52 170.86,570.91 170.00,570.73
             168.09,570.34 166.16,568.82 164.84,567.26
             163.76,565.97 163.64,563.92 162.94,562.27
             162.43,561.04 161.68,559.90 161.03,558.72
             160.54,558.71 160.04,558.69 159.54,558.68
             158.00,562.50 156.49,566.33 154.92,570.13
             153.64,573.22 152.31,575.70 148.55,572.31
             146.01,570.02 143.64,571.67 141.79,573.75
             138.78,577.12 135.90,580.59 132.90,584.09
             130.53,578.86 128.44,574.24 126.08,569.05
             120.92,573.91 122.24,579.57 121.04,584.44
             120.77,585.54 121.00,586.77 121.00,587.93
             121.00,596.08 117.53,598.88 109.35,597.04M 294.89,174.53
           C 296.29,175.32 298.12,177.07 299.00,176.68
             300.33,176.08 301.93,173.91 301.79,172.57
             301.33,167.99 300.16,163.48 298.97,157.43
             296.11,159.97 293.37,161.26 293.26,162.74
             293.00,166.41 293.94,170.16 294.89,174.53M 259.58,303.06
           C 261.80,305.03 264.09,307.41 267.22,305.10
             269.41,303.48 269.50,296.33 266.92,294.30
             261.88,290.31 260.20,297.30 255.72,297.93
             257.08,299.82 258.07,301.19 259.58,303.06M 148.90,325.67
           C 149.45,328.96 150.95,332.41 154.53,331.48
             157.90,330.59 157.46,326.88 156.43,323.88
             156.38,323.73 156.26,323.60 156.16,323.47
             154.91,321.74 153.32,318.39 152.47,318.57
             149.53,319.19 147.35,321.33 148.90,325.67M 210.10,308.24
           C 210.67,309.58 210.88,311.39 211.89,312.13
             213.01,312.96 215.26,313.52 216.26,312.93
             217.53,312.17 218.86,309.99 218.64,308.67
             218.42,307.32 216.44,305.43 215.10,305.31
             213.49,305.17 211.74,306.66 210.10,308.24M 279.83,281.30
           C 279.83,277.92 279.83,274.53 279.83,270.68
             277.43,271.44 275.78,271.97 273.59,272.67
             275.27,276.12 272.99,281.33 279.83,281.30M 284.02,434.79
           C 284.41,436.85 284.80,438.90 285.47,442.42
             287.06,439.60 288.11,438.32 288.49,436.86
             288.82,435.62 288.37,434.17 288.26,432.82
             286.86,433.21 285.47,433.61 284.02,434.79M 167.42,283.97
           C 169.97,280.72 169.79,279.96 165.80,277.16
             161.82,281.57 161.95,282.70 167.42,283.97M 231.83,309.47
           C 234.19,306.21 233.83,303.98 229.46,303.08
             228.99,305.73 225.78,308.91 231.83,309.47 Z"
      />
      <path
        fill="#91F861"
        d="M 537.75,268.43
           C 543.53,286.41 536.33,301.83 530.32,317.75
             527.21,325.96 524.02,333.37 516.37,338.15
             515.41,338.74 514.38,339.32 513.61,340.12
             511.51,342.30 509.52,344.59 507.91,346.38
             513.93,353.47 520.07,359.40 524.62,366.38
             532.80,378.92 534.88,392.99 531.66,407.66
             529.25,418.65 526.23,429.48 518.40,438.07
             508.28,449.19 497.49,459.52 484.40,467.27
             464.54,479.03 443.07,485.75 420.35,489.11
             412.99,490.20 405.82,492.52 398.46,493.62
             395.89,494.00 393.03,492.39 390.31,491.69
             390.99,489.18 391.78,486.68 392.31,484.14
             392.65,482.54 393.27,480.45 392.58,479.28
             390.78,476.21 390.34,474.07 393.85,471.90
             395.01,471.18 396.27,468.26 395.82,467.71
             394.07,465.57 391.79,463.74 389.40,462.32
             388.70,461.91 386.36,462.92 385.80,463.88
             383.27,468.17 381.17,468.46 378.27,464.14
             377.83,463.48 373.90,463.90 373.28,464.89
             372.17,466.66 372.54,469.28 371.95,471.45
             371.28,473.96 370.46,478.33 369.32,478.46
             365.82,478.85 362.04,477.89 358.56,476.83
             357.42,476.48 356.15,474.22 356.15,472.83
             356.14,465.06 356.50,457.28 356.76,449.51
             356.80,448.19 356.90,446.87 357.00,445.56
             358.32,429.19 359.53,412.80 361.02,396.45
             362.15,384.06 363.25,371.65 365.10,359.36
             367.72,342.00 370.70,324.68 374.23,307.49
             375.13,303.14 378.68,299.33 381.33,294.72
             379.67,293.81 377.38,292.57 374.89,291.22
             375.89,285.90 381.75,281.43 377.35,274.66
             380.92,273.48 384.14,272.69 387.11,271.35
             390.19,269.96 393.03,268.03 395.97,266.34
             393.38,264.90 390.78,263.47 388.19,262.02
             387.79,261.80 387.41,261.55 386.66,261.10
             390.23,255.92 388.58,251.31 384.32,246.46
             386.98,245.18 389.16,243.87 391.50,243.04
             409.62,236.66 428.18,232.04 447.31,230.04
             459.55,228.77 471.85,227.30 484.13,227.19
             496.78,227.09 509.71,229.31 519.14,238.30
             527.23,246.02 535.27,254.62 537.03,266.72
             537.10,267.18 537.38,267.61 537.75,268.43M 454.98,379.03
           C 452.62,379.54 450.13,379.73 447.93,380.62
             440.45,383.66 433.01,386.85 425.68,390.25
             424.45,390.82 423.37,392.63 423.04,394.05
             421.22,401.94 419.36,409.84 418.10,417.83
             417.17,423.75 417.13,429.81 416.61,436.94
             424.79,435.14 431.77,433.73 438.68,432.04
             449.03,429.52 457.05,422.89 464.33,415.77
             467.87,412.31 472.36,407.85 470.92,401.72
             468.72,392.40 463.97,384.60 454.98,379.03M 479.99,289.79
           C 477.40,282.73 470.73,278.25 463.06,277.94
             458.43,277.75 454.27,278.47 452.11,282.88
             449.76,287.66 447.85,292.67 446.05,297.68
             443.81,303.92 442.03,310.31 439.79,316.54
             437.57,322.74 434.23,328.64 435.26,336.34
             437.88,335.89 440.23,335.53 442.57,335.09
             452.63,333.16 459.13,326.32 464.88,318.59
             471.27,310.00 477.94,301.54 479.99,289.79M 392.43,423.00
           C 393.94,422.00 395.51,421.07 396.85,419.87
             396.98,419.75 395.77,417.28 394.97,417.14
             393.09,416.83 391.08,417.26 387.71,417.49
             389.64,420.20 390.63,421.60 392.43,423.00M 389.00,356.59
           C 389.71,358.04 390.42,359.50 391.13,360.95
             392.08,360.08 393.83,359.20 393.82,358.33
             393.81,356.58 392.85,354.83 392.29,353.08
             391.19,353.97 390.10,354.86 389.00,356.59M 518.00,398.41
           C 517.30,396.96 516.61,395.50 515.92,394.05
             514.95,395.12 513.30,396.13 513.20,397.28
             513.08,398.77 514.18,400.37 514.76,401.93
             515.84,401.03 516.92,400.14 518.00,398.41M 508.21,384.60
           C 509.36,385.30 510.51,386.01 511.66,386.71
             512.49,384.82 513.32,382.93 514.15,381.04
             513.54,380.66 512.94,380.29 512.33,379.91
             511.08,381.26 509.83,382.61 508.21,384.60 Z"
      />
    </svg>
  );

  return (
    <Link
      component={ RouterLink }
      to="/"
      color="inherit"
      aria-label="go to homepage"
      sx={ {lineHeight: 0} }
    >
      <Box
        sx={ {
          width: single ? 64 : 75,
          lineHeight: 0,
          cursor: 'pointer',
          display: 'inline-flex',
          ...sx,
        } }
      >
        { single ? singleLogo : fullLogo }
      </Box>
    </Link>
  );
}

Logo.propTypes = {
  single: PropTypes.bool,
  sx: PropTypes.object,
};

export default memo(Logo);
